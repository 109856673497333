import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/web'
import Overlay from './Overlay'
import Scene from './Scene'
import Bottom from './bottom'

export default function HoemePage() {
    // This spring controls the background and the svg fill (text color)
    const [{ background, fill }, set] = useSpring({ background: '#202020', fill: '#f0f0f0' }, [])
    document.getElementsByTagName('iframe')
    var scrollFunction = function(e) {
        e = e || window.event;
        e.preventDefault && e.preventDefault(); //禁止浏览器默认事件
        if (e.wheelDelta) { //判断浏览器IE，谷歌滑轮事件
            if (e.wheelDelta > 0) { //当滑轮向上滚动时
            }
            if (e.wheelDelta < 0) { //当滑轮向下滚动时
            }

        } else if (e.detail) { //Firefox滑轮事件
            if (e.detail > 0) { //当滑轮向上滚动时
            }
            if (e.detail < 0) { //当滑轮向下滚动时
            }
        }
    }
//给页面绑定滑轮滚动事件
    if (document.addEventListener) { //firefox
        document.addEventListener('DOMMouseScroll', scrollFunction, false);
    }
//滚动滑轮触发scrollFunction方法  //ie 谷歌
    window.addEventListener('mousewheel', scrollFunction, {
        passive: false
    });
    return (
        <>

            <a.main style={{ background ,height:"100vh",position:"absolute"}}>
                <Canvas style={{marginRight:"-50%"}} className="canvas" dpr={[1, 2]}>
                    <Scene setBg={set} />
                    <OrbitControls enablePan={false} enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />
                </Canvas>
                
                 <Bottom/>
            </a.main>
            <Overlay  />


            
        </>
      
    )
  }
  