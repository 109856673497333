import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import { JsonRpcProvider } from '@mysten/sui.js';
import {Message, useToaster} from "rsuite";
import './style.css';


const Button = styled.button`
  border-radius: 18px;
  background: none;
  color:white;
  border: 1px solid grey;
  padding: 8px;
  margin-right: 12px;
  cursor: pointer;
  @media(max-width:800px){
    padding:4px;
    margin-right:3px;
  }
`

export default function Bottom() {
  // Just a Figma export, the fill is animated
    const [user,setUser] = useState()
    const toaster = useToaster();
    const [placement, setPlacement] = React.useState('topCenter');
    const [type, setType] = React.useState('info');

    const messageS = (<Message showIcon type={"success"}>{'success'}: Success.</Message>);
    const messageE = (<Message showIcon type={"error"}>{'error'}: Failed to fetch.</Message>);
    const messageI = (<Message showIcon type={"info"}>{'info'}: Waiting a minute.</Message>);

      const handleSUI=()=>{
          toaster.push(messageI, {placement} )
        if (user&&user.length>0){
        fetch('https://faucet.devnet.sui.io/gas',{
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({FixedAmountRequest:{recipient:user[0]}})
        })
            .then((response) =>  response.json())
            .then((data) => {
                setTimeout(()=>{
                    toaster.push(messageS, {placement} )
                },3000)
            })
            .catch(e=> {
                setTimeout(()=>{
                    toaster.push(messageE, {placement})
                },3000)
            })

        }
      }


    const onClick= async ()=>{

         if (window.suiWallet){
            const suiWallet = window.suiWallet
            const provider = new JsonRpcProvider('https://gateway.devnet.sui.io:443')
            suiWallet.requestPermissions()
            suiWallet.hasPermissions()
            setUser(await suiWallet.getAccounts())
         }else {
             alert('Please Download SUI Wallet Plugin')
             window.location.replace("https://chrome.google.com/webstore/detail/sui-wallet/opcgpfmipidbgpenhmajoajpbobppdil")
         }
    }


    const mb = window.innerWidth > 800


    return (
    <div style={{position:"absolute",top:0,minWidth:"100vw",height:"80px"}}>
      <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",height:"100%"}}>


          {/*logo*/}
          <a href="https://market.swiftnftmarket.io">

          <div style={{display:"flex",alignItems:"center"}}>
                <img style={{width:mb?"70px":"40px",height:mb?"70px":"40px",marginLeft:mb?"30px":"5px"}} src='/nftlogo.png'/>
                <span style={{color:"white",fontWeight:mb?900:500,fontSize:mb?"":"12px"}}>Swift NFT Market</span>
              </div>
      </a>
          {/** github/twitter */}
              <div style={{margin:mb? "0 40px":"0 10px",display:"flex",justifyContent:"center",alignItems:"center"}}>

                  {user&&user.length>0?<Button style={mb?{}:{position:"absolute",top:60,right:10}} onClick={handleSUI}>SUI Faucet</Button>:null}
                  {user&&user.length>0?<Button>{user[0].slice(0,7)+"..."+user[0].slice(-7)}</Button>: <Button onClick={onClick}>SUI Wallet</Button>}

                <a href='https://github.com' >
                {/* <img style={{width:"40px"}} src="/github.png" alt=""/> */}
                <svg  style={{width:"43px",marginRight:window.innerWidth>700?"12px":"2px"}} height="30px" aria-hidden="true" viewBox="0 0 16 16" version="1.1" width="40px" data-view-component="true" class="octicon octicon-mark-github v-align-middle">
                    <path  style={{width:"40px"}} fill="white" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"/>
                </svg>
                </a>

                <a href='https://twitter.com/SwiftNFTMarket'>
                <img style={{width:"30px"}} src="/twitter.svg" alt=""/>
                </a>
              </div>

      </div>
    </div>
  )
}
