import React from 'react'

export default function Overlay() {

  // return (
  //   <div style={{height:"1000px"}}>

  //   <div className="overlay" style={{border:"10px solid red"}}>
  //     <div className='overDiv'>
  //       <h1 className='Overh1' ><span className='linerCol'>M</span>ake <span className='linerCol'>N</span>FT <span className='linerCol'>C</span>irculation <span className='linerCol'>E</span>asier </h1>
  //       <p  className='Overp' id="dowebok">A faster and safer multifunctional NFT trading market on SUI blockchain</p>
  //     </div>



  //     <div className='module2'>
       
  //       <div>
  //         <i className="iconfont icon-juzhongduiqi linerCol" style={{fontSize:'38px'}}>
  //         </i><span className='Transaction'>Marketplace</span><br/>
  //         <p className='Frag'>Providing  more efficient and interesting NFT dealing experience</p>
  //       </div>
        
  //       <div>
  //         <i className="iconfont icon-jiaoyi linerCol" style={{fontSize:'38px'}}>
  //         </i><span className='Transaction'>NFT-Fi</span><br/>
  //         <p className='Frag'>Perfect combination of NFT and Defi</p>
  //       </div>

  //       <div>
  //         <i className="iconfont icon-shujufenxi linerCol" style={{fontSize:'38px'}}>
  //         </i><span className='Transaction'>Data Pivot</span><br/>
  //         <p className='Frag'>Be fully aware of NFT market conditions from here</p>
  //       </div>
  //     </div>


  //     {/* <div className='module1'>
  //       <div>
  //         <i className="iconfont icon-create-course linerCol" style={{fontSize:'38px'}}>
  //         </i><span className='Transaction'>NFT Creation</span><br/>
  //         <p className='Frag'>Minting  your own NFT in a quick  and simple way without programming</p>
  //       </div> */}
       
  //       {/* <div>
  //         <a href="https://twitter.com/SwiftNFTMarket" target="_blank" rel="noreferrer" style={{height:'100%'}} ><i className="iconfont icon-juzhongduiqi linerCol" style={{fontSize:'38px'}}>
  //         </i><span className='Transaction'>Fragmentization</span><br/>
  //         <p className='Frag'>One-click fragmentization to make your NFT circulate</p></a>
  //       </div> */}
        
  //     </div>
  //   </div>
     
  // )
const mb = window.innerWidth >800
return(
  <div className="h1font" style={{position: "absolute",top:mb?"20%":"10%",left:mb?100:10}}>
      <h1 style={{color:"#e9af5b",fontSize:mb?90:40}}><span className='linerCol'>M</span>ake <span className='linerCol'>N</span>FT <p style={{marginTop:"30px"}}>      <span className='linerCol'>C</span>irculation <span className='linerCol'>E</span>asier</p> </h1>
      <p className="Frag " style={{color:"#8a8aa0",position: "absolute",wordWrap:"break-all"}}>A faster and safer multifunctional NFT trading market on SUI blockchain</p>

          <div style={{display:mb?"flex":"",marginTop:window.innerWidth<=1280?mb?"15%":"20%":"30%"}}>

             <div style={{margin:"0 10px"}}>
               <i className="iconfont icon-juzhongduiqi linerCol" style={{fontSize:'38px'}}>
               </i><span className='Transaction'>Marketplace</span><br/>
               <p className='Frag'>Providing more efficient and funny </p>
               <p className='Frag'>NFT dealing experience</p>
             </div>

             <div style={{margin:"0 20px"}}>
               <i className="iconfont icon-jiaoyi linerCol" style={{fontSize:'38px'}}>
               </i><span className='Transaction'>NFT-Fi</span><br/>
               <p className='Frag'>Perfect combination of NFT and Defi</p>
               <p className='Frag'>Fragment Borrowing and Others</p>
             </div>

             <div style={{margin:"0 20px"}}>
               <i className="iconfont icon-shujufenxi linerCol" style={{fontSize:'38px'}}>
               </i><span className='Transaction'>Data Pivot</span><br/>
               <p className='Frag'>Be fully aware of NFT Marketplace  </p>
               <p className='Frag'>conditions from here</p>
             </div>



          </div>

  </div>
)

}
