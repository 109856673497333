import React from 'react'

import HoemePage from './pages/Home'

export default function App() {
  // This spring controls the background and the svg fill (text color)
  return (
   <HoemePage/>


  )
}
